import request from '@/utils/request'
// 获取课程详情
export function courseData (id) {
    return request({
      url: `/spectrum/mp/courses/${id}`,
      method: 'get'
    })
  }
// 知识模块类型关系树
export function chartsTree (id) {
    return request({
      url: `/spectrum/mp/charts/course/${id}/tree`,
      method: 'get'
    })
  }
// 新知识层级图谱
export function courseCharts (id) {
    return request({
      url: `/spectrum/mp/charts/course/${id}/point/level`,
      method: 'get'
    })
  }