import G6 from '@antv/g6'

/**
   * 初始化数据
   * @param swtichRenderer true切换svg渲染
   * @param style 风格：1白,黑；
   * @param dataType 数据类型:1普通图谱，2专业图谱
   * @param type 图谱类型：1预览,2全屏，3详情小屏，4学习模式小屏
   * @param data 数据
   * @param container 容器
   * @param smailChart 小窗
   * @param courseName 课程名
*/
export function int (swtichRenderer, style, dataType, type, data, container, smailChart) {
  let graph = null
  const nodes = data.nodes
  const clusterMap = new Map()
  let clusterId = 0
  let layoutType = 1// 0，随机，1:自定义

  nodes.forEach(function (node) {
    if (node.cluster && clusterMap.get(node.cluster) === undefined) {
      clusterMap.set(node.cluster, clusterId)
      clusterId++
    }
    if (!node.style) {
      node.style = {}
    }
    if (!node.labelCfg) {
      node.labelCfg = {}
      node.labelCfg.style = {}
    }
    if (!node.id.includes('a') && !node.id.includes('border')) {
      node.img = node.pointImage
      node.labelCfg.position = 'bottom'
      node.labelCfg.offset = 30
      node.labelCfg.style.fontSize = 60
    }
  })
  // 按照数组顺序依次配对不同颜色
  // data.edges.forEach(function (edges) {
  //   edges.style = {}
  //   const cid = clusterMap.get(edges.cluster)
  //   if (cid !== undefined) {
  //     edges.style.stroke = colors[cid % colors.length]
  //   }
  // })
  let width= container.scrollWidth
  let height = 400

  graph = new G6.Graph(layoutData(swtichRenderer, style, dataType, type, layoutType === 0 ? 1 : 2, width, height))

  graph.get('canvas').set('localRefresh', false)

  edgesData(data, type, smailChart)
  
  graph.data(data)
  graph.render()
  graph.zoomTo(0.1, { x: width/2, y: height/2 })
  // graph.fitView(1)
  graph.focusItem('a'+String(data.nodes[0].moduleId), true, {
    easing: 'easeCubic',
    duration: 400
  })
  return { graph }
}

/**
   * 边数据处理
   * 边关系：1父子所属关系同色虚线，2知识关系深色实线+label，3即有所属关系又有知识关系的是同色虚线+label
   * 第一版边关系：1实线，2虚线
   * @param type 图谱类型：1预览,2全屏，3详情小屏，4学习模式小屏
   * @param data 数据
   * @param smailChart true小窗图谱
*/
export function edgesData (data, type, smailChart) {
  const colors = colorsData(3)
  if (data.edges.length === 0) {
    return false
  }
  // let cluster = data.edges[0].cluster
  // let index = 0
  for (const i in data.edges) {
    data.edges[i].style = {}
    if (data.edges[i].label !== '') {
      data.edges[i].label = (sessionStorage.getItem('lang') || 'zh') === 'en' ? data.edges[i].labelEN : data.edges[i].label
    }
    // if (data.edges[i].cluster == cluster) {
    //   data.edges[i].color = color[index]
    // } else {
    //   index = +index + 1
    //   cluster = data.edges[i].cluster
    //   data.edges[i].color = color[index]
    // }
    if (type === 3 || type === 4) {
      data.edges[i].style.stroke = '#AAAAAA'
      if (!smailChart) { // 小窗层级关系
        data.edges[i].id = String(i)// id和source一致会无法展示边，强制化0
        data.edges[i].style.lineDash = [12, 12]
        // if (data.edges[i].level === 0) {
        //   data.edges[i].style.stroke = 'rgb(85 0 0 / 0%)'
        // }
      }
    } else {
      if (!data.edges[i].pointLevelRelationship) {
        data.edges[i].style.stroke = '#fff'
        data.edges[i].style.startArrow = {
          path: G6.Arrow.vee(10, 10, 10),
          d: 10,
          lineDash: [0, 0],
          lineWidth: 3,
          opacity: 0
        }
        data.edges[i].style.endArrow = {
          path: G6.Arrow.vee(10, 10, 10),
          d: 10,
          lineDash: [0, 0],
          lineWidth: 3,
          fill: '#fff'
        }
      } else {
        if (data.edges[i].pointLevelRelationship === 1) {
          data.edges[i].style.stroke = colors[data.edges[i].moduleSort - 1]
          data.edges[i].style.lineDash = [12, 12]
          // data.edges[i].style.endArrow = false// 注意：边绿屏的罪魁祸首！涉及到多种样式，不要用false关闭，直接改透明度
          data.edges[i].style.endArrow = {
            path: G6.Arrow.vee(10, 10, 10),
            d: 10,
            lineDash: [0, 0],
            lineWidth: 3,
            opacity: 0
          }
        } else if (data.edges[i].pointLevelRelationship === 2) {
          data.edges[i].style.stroke = '#AAAAAA'
          data.edges[i].style.startArrow = {
            path: G6.Arrow.vee(10, 10, 10),
            d: 10,
            lineDash: [0, 0],
            lineWidth: 3,
            opacity: 0
          }
          data.edges[i].style.endArrow = {
            path: G6.Arrow.vee(10, 10, 10),
            d: 10,
            lineDash: [0, 0],
            lineWidth: 3,
            fill: '#AAAAAA'
          }
        } else if (data.edges[i].pointLevelRelationship === 3) {
          data.edges[i].style.stroke = colors[data.edges[i].moduleSort - 1]
          data.edges[i].style.lineDash = [12, 12]
          data.edges[i].style.startArrow = {
            // path: 'M 0,0 L 8,4 L 8,-4 Z',
            path: G6.Arrow.vee(10, 10, 10),
            d: 10,
            lineDash: [0, 0],
            lineWidth: 3,
            opacity: 0
          }
          data.edges[i].style.endArrow = {
            // path: 'M 0,0 L 8,4 L 8,-4 Z',
            path: G6.Arrow.vee(10, 10, 10),
            d: 10,
            lineDash: [0, 0],
            lineWidth: 3,
            fill: colors[data.edges[i].moduleSort - 1]
          }
        }
      }

      if (data.edges[i].level === 'border') {
        data.edges[i].style.stroke = '#fff'
        data.edges[i].size = 7
        data.edges[i].style.lineDash = [120, 120]
        data.edges[i].style.shadowColor = 'yellow'
      }
      // if (data.edges[i].level === 0) {
      //   data.edges[i].style.stroke = 'rgb(85 0 0 / 0%)'// 假边透明化
      //   // data.edges[i].style.startArrow = false
      //   data.edges[i].style.endArrow = false
      // }
    }
  }
  return data
}

/**
   * 布局处理
   * @param style 1白，2黑
   * @param mode 1预览,2全屏，3详情小屏，4学习模式小屏
   * @param layoutType 全屏(1导力布局，2自定义布局)
*/
export function layoutData (swtichRenderer, style, dataType, mode, layoutType, width, height, minimap) {
  let layout
  let layoutData = {
    width,
    height,
    minZoom: 0.01,
    maxZoom: 1.1
  }
  if (mode === 1 || mode === 2) {
    layout = {
      container: 'container',
      renderer: swtichRenderer ? 'svg' : '', // 导出清晰图片必须开启
      ...layoutData,
      fitView: false,
      //   设置节点收到某些事件后的样式变化
      nodeStateStyles: {
        click: {
          fill: 'red',
          labelCfg: {
            style: {
              background: {
                fill: '#red',
                stroke: 'green',
                padding: [10, 10, 10, 10],
                radius: 20,
                lineWidth: 3
              }
            }

          }

        }
      },
      modes: {
        default: ['drag-canvas', 'drag-node', 'zoom-canvas', 'click-select', 'brush-select']
        // edit: ['click-select']
      },
      defaultCombo: {
        type: 'rect',
        labelCfg: {
          refY: 2
        },
        style: {
          fill: 'rgba(255, 255, 255, 0.2)'
        }
      },
      defaultNode: {
        type: 'image',
        size: nodeSize(0),
        style: {
          lineWidth: 1,
          stroke: '#127478',
          fill: 'rgba(255, 255, 255, 0)',
          shadowBlur: 50
        },
        labelCfg: {
          style: {
            fill: '#fff',
            fontSize: 50,
            lineWidth: 500
            // background: {
            // fill: '#ffffff'
            //   stroke: 'green',
            //   padding: [10, 10, 10, 10],
            //   radius: 20,
            //   lineWidth: 3
            // }
          }
        }
      },
      defaultEdge: {
        size: 2,
        type: 'cubic-vertical',
        labelCfg: {
          // refY: 13,
          autoRotate: true,
          style: {
            stroke: '#2A1D32',
            lineWidth: 10,
            fontSize: 30,
            fill: '#AAAAAA'
          }
        }
      },
      layout: {},
      animate: true
    }

    if (layoutType === 1) {
      layout.layout = {
        type: 'forceAtlas2',
        preventOverlap: true,
        kr: 500,
        center: [0, 0],
        linkDistance: 80,
        maxIteration: 300,
        nodeSpacing: 200
      }
    } else if (layoutType === 2) {
      layout.layout = {
        // type: 'random',
        preventOverlap: true
      }
    }

    if (mode === 2) {
      layout.plugins = [minimap]
    }
    // 黑色风格
    if (style === 2) {
      layout.defaultNode.labelCfg.style.fill = '#C0C0C0'
      layout.defaultEdge.labelCfg.style.fill = '#C0C0C0'
      layout.defaultEdge.labelCfg.style.stroke = '#000000'
    }
  } else if (mode === 3 || mode === 4) { // 小图力导向布局
    layout = {
      container: 'container-inner',
      ...layoutData,
      fitView: true,
      modes: {
        default: ['drag-canvas', 'drag-node', 'zoom-canvas']
      },
      defaultNode: {
        size: 300,
        type: 'image',
        labelCfg: {
          style: {
            fill: '#333',
            fontSize: 15
          }
        }
      },
      defaultEdge: {
        size: 2,
        type: 'cubic-vertical',
        style: {
          endArrow: {
            path: 'M 0,0 L 8,4 L 8,-4 Z',
            fill: '#AAAAAA'
          },
          lineDash: null
        },
        labelCfg: {
          style: {
            stroke: '#F8F8FF',
            lineWidth: 10,
            fontSize: 15,
            fill: '#AAAAAA'
          }
        }
      },
      layout: {
        type: 'forceAtlas2',
        preventOverlap: true,
        nodeSpacing: 1500
      }
    }
  } else if (mode === 6) {
    layout = {
      container: 'container',
      ...layoutData,
      fitView: false,
      modes: {
        default: ['drag-canvas', 'drag-node']
      },
      defaultNode: {
        type: 'image',
        size: nodeSize(0),
        style: {
          lineWidth: 1,
          stroke: '#127478',
          fill: 'rgba(255, 255, 255, 0)',
          shadowBlur: 50
        },
        labelCfg: {
          style: {
            fill: '#333',
            fontSize: 50,
            lineWidth: 500
          }
        }
      },
      defaultEdge: {
        size: 2,
        type: 'cubic-vertical',
        labelCfg: {
          // refY: 13,
          autoRotate: true,
          style: {
            stroke: '#f3f3fd',
            lineWidth: 10,
            fontSize: 30,
            fill: '#AAAAAA'
          }
        }
      },
      layout: {
        type: 'force',
        preventOverlap: true,
        nodeStrength: -500,
        nodeSpacing: 100,
        center: [-600, -200],
        width,
        height
      },
      animate: false
    }
  }
  return layout
}

export function nodeSize (type) {
  let size = [120, 100, 80, 60, 40]
  return size[type]
}

export function colorsData (type) {
  let colors = []
  if (type === 1) {
    colors = [
      '#8F7C7C',
      '#6A7D76',
      '#445FA3',
      '#6D7D9B',
      '#9E4343',
      '#3E9797',
      '#2A6666',
      '#533D92',
      '#796188',
      '#763F60',
      '#5B7756',
      '#77702C',
      '#7C4D3E',
      '#5992B2',
      '#5E3165',
      '#B98E7B',
      '#A37845',
      '#485233',
      '#9C4483',
      '#63985A'
    ]
  } else if (type === 2) {
    colors = [
      '#127478',
      '#8677A9',
      '#4084A8',
      '#B4846D',
      '#7E5A5B',
      '#284A6E',
      '#72826E',
      '#8F394A',
      '#967F77',
      '#5EABAB'
    ]
  } else if (type === 3) {
    colors = [
      '#786EA5',
      '#34B39A',
      '#A3AC7E',
      '#C18663',
      '#B16975',
      '#88ADA6',
      '#A88462',
      '#C6A69C',
      '#A1AFC9',
      '#7D6B8A',
      '#70ADCC',
      '#75878A',
      '#665757',
      '#C2CCD0',
      '#D3B17D',
      '#C49BD8',
      '#927C82',
      '#888476',
      '#D69F9F',
      '#738490',
      '#558DD8',
      '#8B43B7',
      '#E09032',
      '#B9C158',
      '#149188',
      '#429255',
      '#A8CCAE',
      '#9CB8C3',
      '#E0856F',
      '#FFA7B1',
      '#3F62A9',
      '#1BB1E7',
      '#BD4FA2',
      '#8A3C42',
      '#3E7165',
      '#FBA676',
      '#443C92',
      '#85CA9A',
      '#C1AFCD',
      '#8C8959',
      '#5CCED6',
      '#8FC9D4',
      '#A0CC55',
      '#6C5086',
      '#1A95BC',
      '#3A81AD',
      '#F0CB46',
      '#B3623F',
      '#AB7BF9',
      '#8A98FC'
    ]
  } else if (type === 4) {
    colors = [
      '#7E73B0',
      '#6F85AF',
      '#A3AC7E',
      '#C18663',
      '#B16975',
      '#88ADA6',
      '#A88462',
      '#A98175',
      '#A1AFC9',
      '#7D6B8A',
      '#63A2C3',
      '#75878A',
      '#5C4D4D',
      '#C2CCD0',
      '#D3B17D',
      '#C49BD8',
      '#927C82',
      '#7D796B',
      '#D69F9F',
      '#738490'

    ]
  }
  return colors
}
