<template>
  <div class="course-chart">
    <div class="part1" v-if="courseData">
      <img
        width="100"
        style="margin-top: 20px"
        src="../../assets/course/icon04.png"
      />
      <p style="font-size: 16px; font-weight: 500; margin: 20px 0 10px">
        亲爱的老师，合作愉快！
      </p>
      <p>是我呀，Lumigo，您的文件收集小助手。</p>
      <p>我们一起开启您的特色AI课程知识图谱吧！🗝</p>
      <p style="margin: 10px 0 20px">复制链接前往网页端效果体验更好哦~</p>
      <img width="60" src="../../assets/course/icon02.png" alt="" />
      <p style="font-size: 16px; font-weight: 500; margin: 10px">{{courseData.nameCN}}</p>
      <div class="text2">
        <img width="152" src="../../assets/course/icon03.png" alt="" />
        <div class="text2-r-box">
          <div class="text2-r">
            <p class="text2-r-c">了解您的课程</p>
            <p class="text2-r-e">
              <span style="display: block; width: 100%">Cource</span>
              <span>Info</span>
            </p>
          </div>
          <div class="text2-r">
            <p class="text2-r-c">宏观知识框架</p>
            <p class="text2-r-e">
              <span style="display: block; width: 100%">Module</span>
              <span>Units</span>
            </p>
          </div>
          <div class="text2-r">
            <p class="text2-r-c">微观知识点梳理</p>
            <p class="text2-r-e" style="top: -16px">Nodes</p>
          </div>
        </div>
      </div>
      <img
        width="50"
        style="margin: 50px 0"
        src="../../assets/course/loading.gif"
        alt=""
      />
    </div>
    <div class="part2"  v-if="courseData">
      <div class="part2-item" style="display: block; text-align: center">
        <img height="16" src="../../assets/course/title.png" alt="" />
      </div>
      <div class="part2-item">
        <span class="school">
          <img :src="schoolsImg" alt="" />
          <span>{{ schoolsName }}</span>
        </span>
        <div class="nameCN">{{ courseData.nameCN }}</div>
        <div
          v-if="courseData.introduction"
          style="margin-top: 12px"
          class="left-course-tein"
        >
          <span
            class="introduction"
            :class="[{ 'mutipleText-overflow': !IntroIsGrow }]"
            >{{ courseData.introduction }}</span
          >
          <div
            v-if="!isShowIStretch && IntroIsGrow"
            class="BStretch"
            @click="IntroIsGrow = false"
          >
            <div>
              <span>收起</span>
              <img width="12" height="8" src="@/assets/course/xiala_ic2@2x.png" />
            </div>
          </div>
          <div
            v-if="!isShowIStretch && !IntroIsGrow"
            class="TStretch"
            @click="IntroIsGrow = true"
          >
            <span>展开</span>
            <img width="12" height="8" src="@/assets/course/xiala_ic@2x.png" />
          </div>
        </div>
        <div class="total">
          <div class="total-item">
            <span class="total-label">学分</span>
            <span class="total-num">{{ courseData.credit }}</span>
          </div>
          <div class="total-item">
            <span class="total-label">学时</span>
            <span class="total-num">{{ courseData.classHour }}</span>
          </div>
          <div class="total-item">
            <span class="total-label">模块</span>
            <span class="total-num">{{ courseData.modules }}</span>
          </div>
          <div class="total-item">
            <span class="total-label">知识点</span>
            <span class="total-num">{{ courseData.points }}</span>
          </div>
        </div>
        <div class="teacher-list">
          <div
            class="teacher-item"
            v-for="(item, i) in courseData.teachers"
            :key="i"
          >
            <span class="teacher-title">{{ item.title }}</span>
            <img width="46" :src="item.avatar" />
            <span class="teacher-name">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="part3"  v-if="courseData">
      <div class="part-title">课程目标</div>
      <img src="../../assets/course/icon01.png" />
      <div class="text">{{ courseData.target }}</div>
    </div>
    <div class="part4">
      <img
        width="50"
        style="margin: 30px 0"
        src="../../assets/course/loading.gif"
        alt=""
      />
      <div id="container" />
      <img
        class="robort"
        width="76"
        src="../../assets/course/robort.png"
        alt=""
      />
      <img
        width="210"
        style="margin-top: 170px"
        src="../../assets/course/btn.png"
        alt=""
      />
      <span class="trip">滑动查看更多</span>
      <span style="margin-top: -30px; margin-left: 20px; font-size: 14px"
        v-clipboard:copy="`https://seentao.lumidays.com`"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        >复制网址，体验更多</span
      >
    </div>
  </div>
</template>

<script>
import { courseData, courseCharts } from "@/api/chart";
import { beforeInt } from "@/views/chart/beforeInt.js";
import { int } from "@/views/chart/chartData.js";
export default {
  name: "ChartModel",
  data() {
    return {
      courseId: this.$route.query.courseId||1,
      graph: null,
      data: {
        edges: [],
        nodes: [],
      },
      layoutType: 0,
      smailChart: true, // true：知识关系，false:层级关系
      x: -300,
      smallX: 0,
      relatedNodesArr: [],
      courseData: null,
      schoolsName: "",
      schoolsImg: "",
      IntroIsGrow: true,
      isShowIStretch: false,
      userName:''
    };
  },
  mounted() {
    this.getCourseData();
    this.getData();
  },
  destroyed() {
    this.graph.destroy();
  },
  methods: {
    async getCourseData() {
      const res = await courseData(this.courseId);
      const { success, data } = res.data;
      if (success) {
        this.courseData = data;
        this.schoolsName = data.schools[0].name;
        this.schoolsImg = data.schools[0].img;
        this.$nextTick(() => {
          if (
            document
              .querySelector(".introduction")
              .getBoundingClientRect().height > 56
          ) {
            this.isShowIStretch = false;
            this.IntroIsGrow=false
          }else {
            this.isShowIStretch = true;
            this.IntroIsGrow=true
          }
        });
      }
    },
    async getData() {
      const res = await courseCharts(this.courseId);
      const { success, data } = res.data;
      if (success) {
        this.data = beforeInt(data, 1);
        data.nodes.forEach((e) => {
          if (e.y === 0) {
            e.x = this.x + 600;
            this.x = this.x + 600;
          }
        });
        this.int(data);
      }
    },
    int(data) {
      const container = document.getElementById("container");
      let graphData = int(false, 1, 1, 1, data, container);
      this.graph = graphData.graph;
      if (typeof window !== "undefined") {
        window.onresize = () => {
          if (!this.graph || this.graph.get("destroyed")) return;
          if (!container || !container.scrollWidth || !container.scrollHeight) {
            return;
          }
          this.graph.changeSize(container.scrollWidth);
        };
        var cs = document.getElementsByTagName("canvas")[0];
        cs.style.borderRadius = "20px";
      }
    },
    onCopy () {
      this.$message.closeAll()
      this.$message({ message: '复制成功', type: 'success' })
    },
    onError () {
      this.$message.error('复制失败')
    }
  },
};
</script>

<style lang="less" scoped>
#container {
  display: -webkit-inline-box;
  justify-content: end;
  width: 90%;
  height: 320px;
  position: relative;
}
.mutipleText-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.course-chart {
  background: #2a1d32;
  .part1 {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-flow: column;
    background: url("../../assets/course/bg1.png");
    background-size: 100% 100%;
    p {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 300;
      color: #ffffff;
    }
    .text2 {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      width: 100%;
    }
    .text2-r {
      position: relative;
    }
    .text2-r-box {
      height: 140px;
      display: flex;
      flex-flow: column;
      justify-content: space-between;
      margin-left: 30px;
      margin-top: 30px;
    }
    .text2-r-c {
      font-size: 16px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
    .text2-r-e {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      opacity: 0.2;
      position: absolute;
      top: -32px;
      span {
        height: 20px;
      }
    }
  }
  .part2 {
    display: flex;
    flex-flow: column;
    padding: 0 27px;
    margin-bottom: 16px;
    .part2-item {
      // display: flex;
      // flex-flow: column;
    }
    .school {
      display: inline-flex;
      align-items: center;
      justify-items: center;
      height: 37px;
      padding: 0 10px;
      background: #433a4e;
      border-radius: 100px;
      margin-top: 27px;
      span {
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        font-size: 14px;
        color: #c7c7c7;
      }
      img {
        width: 22px;
        height: 22px;
        border-radius: 60%;
      }
    }
    .nameCN {
      font-family: PingFang HK, PingFang HK;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      margin: 27px 0 14px;
    }
    .left-course-tein {
      display: flex;
      flex-flow: column;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      border-radius: 10px;
      margin-bottom: 16px;
      .introduction {
        line-height: 28px;
        margin-bottom: 10px;
      }
      .BStretch {
        // .pointer();

        display: inline-flex;
        justify-content: flex-end;
        align-items: flex-end;
        float: right;
        margin-left: 12px;
        min-width: 52px;
        font-size: 12px;
        font-family: "PingFang HK-Semibold", "PingFang HK";
        font-weight: 600;
        color: #333333;
        shape-outside: polygon(0 76px, 100% 76px, 100% 100%, 0 100%);
        line-height: 22px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        > div {
          display: flex;
          align-items: center;
          column-gap: 5px;
        }
      }
      .TStretch {
        // .pointer();

        display: inline-flex;
        justify-content: flex-end;
        align-items: center;
        padding-left: 12px;
        font-family: PingFang HK, PingFang HK;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 22px;
        column-gap: 5px;
      }
    }
    .total {
      display: flex;
      justify-content: space-around;
      .total-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        width: 74px;
        height: 65px;
        background: url("../../assets/course/bg5.png");
        background-size: 100% 100%;
        margin-right: 10px;
        padding: 13px;
        .total-label {
          font-family: PingFang HK, PingFang HK;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          margin-bottom: 8px;
          opacity: 0.4;
        }
        .total-num {
          font-family: PingFang HK, PingFang HK;
          font-weight: 500;
          font-size: 16px;
          color: #ffffff;
          opacity: 0.7;
        }
      }
    }
    .teacher-list {
      // display: flex;
      overflow-x: scroll;
      white-space: nowrap;
      margin-top: 20px;
      margin-bottom: 24px;
      .teacher-item {
        display: inline-flex;
        flex-flow: column;
        align-items: center;
        width: 116px;
        height: 128px;
        background: url("../../assets/course/bg6.png");
        background-size: 100% 100%;
        margin-right: 10px;
        .teacher-title {
          text-align: center;
          font-family: PingFang HK, PingFang HK;
          font-weight: 400;
          font-size: 12px;
          color: #ffffff;
          opacity: 0.5;
          margin-top: 3px;
          width: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; 
        }
        img {
          border-radius: 50%;
          margin-top: 18px;
        }
        .teacher-name {
          text-align: center;
          font-family: PingFang HK, PingFang HK;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          margin-top: 12px;
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; 
        }
      }
    }
  }
  .part3 {
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background: url("../../assets/course/bg3.png");
    background-size: 100% 100%;
    position: relative;
    padding: 0 24px;
    .part-title {
      font-size: 23px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 500;
      color: #ffffff;
      padding: 30px 0 20px;
    }
    img {
      width: 34px;
      position: absolute;
      top: 48px;
      left: 95px;
    }
    .text {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      font-size: 14px;
      font-family: PingFang HK, PingFang HK;
      font-weight: 400;
      color: #ffffff;
      line-height: 26px;
      margin-bottom: 30px;
    }
  }
  .part4 {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    height: 800px;
    // background: url("../../assets/course/bg4.png");
    // background-size: 100% 100%;
    position: relative;
    padding: 0 24px;
    position: relative;
    padding-bottom: 40px;
  }
  .robort {
    position: absolute;
    bottom: 200px;
    left: 30px;
  }
  .trip {
    position: absolute;
    bottom: 234px;
    left: 95px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255,255,255,0.7);
    width: 107px;
    height: 28px;
    background: rgba(255,255,255,0.2);
    border-radius:15px 15px 15px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
