/*
dataType 数据类型:1普通图谱，2专业图谱
viewType:1预览,2全屏，3小屏
*/
export function beforeInt (data, dataType, viewType, specialtyCourse, editLayoutType) {
  if (dataType === 1 && data.nodes.length === 0) {
    return []
  } else {
    // fakeEdges(data)// 只有导力布局需要解决单点离组漂流
    moduleStyle(data, dataType, viewType, specialtyCourse)// 模块样式
    if (editLayoutType === 1) {
      // borderMode(data, specialtyCourse)//模块边界线
    }
    dataTypeChange(data)// id数字转字符串
    return data
  }
}
export function moduleStyle (data, dataType, viewType, specialtyCourse) {
  // dataType1:图标上+文字下node,2:卡片式左图标右文字长方形
  if (dataType === 1) {
    let cluster = -1
    for (const i in data.nodes) {
      data.nodes[i].id = String(data.nodes[i].id)
      if (data.nodes[i].cluster !== cluster) {
        if (viewType !== 3 && viewType !== 6) { // 小图不展示模块
          cluster = data.nodes[i].cluster
          data.nodes.push({
            id: 'a' + String(data.nodes[i].clusterId),
            moduleId: data.nodes[i].moduleId,
            moduleSort: data.nodes[i].moduleSort,
            label: data.nodes[i].cluster,
            size: [250, 230],
            cluster: data.nodes[i].cluster,
            type: 'image',
            img: data.nodes[i].moduleImage,
            x: data.nodes[i].moduleX,
            y: data.nodes[i].moduleY,
            level: 0
          })
        }
      }
    }
    // data.edges.push({
    //   source: 'a' + String(data.nodes[i].clusterId),
    //   target: data.nodes[i].id,
    //   level: 0
    // })// 假边
  } else if (dataType === 2) {
    for (const i in data.nodes) {
      data.nodes[i].id = String(data.nodes[i].id)
    }
    specialtyCourse.forEach(e => {
      data.nodes.push({
        id: 'a' + String(e.id),
        courseSpaceId: e.courseSpaceId,
        courseId: e.id,
        label: e.name,
        size: [1000, 300],
        cluster: e.name,
        type: 'modelRect',
        logoIcon: {
          img: e.img,
          width: 180,
          height: 185,
          y: -90,
          offset: -200
        },
        style: {
          opacity: 0
        },
        labelCfg: {
          style: {
            fill: '#fff',
            fontSize: 100
          }
        },
        description: e.curriculumSystem,
        descriptionCfg: {
          paddingTop: 50,
          style: {
            fill: '#C0C0C0',
            fontSize: 50
          }
        },
        stateIcon: {
          show: false
        },
        preRect: {
          show: false
        },
        x: e.x,
        y: e.y,
        level: 1
      })
    })
  }
  return data
}

export function fakeEdges (data) { // 解决单点离组漂流
  let arr = []
  data.nodes.sort(order)

  // 【A】se：边的唯一标识
  for (const x in data.edges) {
    data.edges[x].se = data.edges[x].source + '+' + data.edges[x].target
  }

  // 【B】所有点node顺序相连
  for (const i in data.nodes) {
    if (parseInt(i) + 1 < data.nodes.length) {
      arr.push({
        source: data.nodes[i].id,
        target: data.nodes[parseInt(i) + 1].id,
        level: 0, // 边
        se: data.nodes[i].id + '+' + data.nodes[parseInt(i) + 1].id
      })
    } else {
      arr.push({
        se: ''
      })
    }
  }

  // 【B】-【A】已有边关系的node不需要透明边
  for (var i = 0; i < arr.length; i++) {
    for (var j = 0; j < data.edges.length; j++) {
      if (arr[i].se === data.edges[j].se) {
        arr.splice(i, 1)
      }
    }
  }

  for (let i in arr) {
    data.edges.push(arr[i])
  }

  return data
}

export function borderMode (data, specialtyCourse) {
  let borderArr = []
  // let rectBox = []
  specialtyCourse.forEach(e => { // border最小xy组成一个坐标，最大xy组成一个坐标
    borderArr.push(
      { x: e.borderMinX, y: e.borderMinY, id: 'border' + String(e.id) + '+1', target: 'border' + String(e.id) + '+2', label: e.name + ': x: ' + e.borderMinX + ' y:' + e.borderMinY },
      { x: e.borderMaxX, y: e.borderMinY, id: 'border' + String(e.id) + '+2', target: 'border' + String(e.id) + '+3', label: e.name + ': x: ' + e.borderMaxX + ' y:' + e.borderMinY },
      { x: e.borderMaxX, y: e.borderMaxY, id: 'border' + String(e.id) + '+3', target: 'border' + String(e.id) + '+4', label: e.name + ': x: ' + e.borderMaxX + ' y:' + e.borderMaxY },
      { x: e.borderMinX, y: e.borderMaxY, id: 'border' + String(e.id) + '+4', target: 'border' + String(e.id) + '+1', label: e.name + ': x: ' + e.borderMinX + ' y:' + e.borderMaxY }
    )
    // rectBox.push(
    //   { width: e.borderMaxX - e.borderMinX, height: e.borderMaxY - e.borderMinY, x: (e.borderMinX + e.borderMaxX) / 2, y: (e.borderMinY + e.borderMaxY) / 2, id: 'border' + String(e.id), label: e.name }
    // )
  })
  borderArr.forEach(e => {
    data.nodes.push({
      id: e.id,
      courseId: e.id,
      // label: e.label,
      size: [50, 50],
      cluster: e.id,
      type: 'star',
      style: {
        fill: '#fff'
      },
      labelCfg: {
        style: {
          fill: '#fff',
          fontSize: 100
        }
      },
      x: e.x,
      y: e.y,
      level: 1
    })
    data.edges.push({
      source: e.id,
      target: e.target,
      level: 'border'
    })
  })
  // rectBox.forEach(e => {
  //   data.nodes.unshift({
  //     id: e.id,
  //     courseId: e.id,
  //     size: [e.width, e.height],
  //     cluster: e.id,
  //     type: 'rect',
  //     style: {
  //       fill: 'rgba(255,255,255,0.2)'
  //     },
  //     x: e.x,
  //     y: e.y,
  //     level: 1,
  //     clickable: false
  //   })
  // })
  return data
}

export function dataTypeChange (data) {
  data.edges.forEach(e => {
    e.source = String(e.source)
    e.target = String(e.target)
  })
  return data
}

export function dataTypeChange2 (tree) {
  tree && tree.forEach(e => {
    if (e.nodeType === 'module') {
      e.uuid = String(e.id)
      e.id = 'm' + String(e.id)
    } else {
      e.uuid = String(e.id)
      e.id = String(e.id)
    }
    if (e.children) {
      dataTypeChange2(e.children)
    }
  })
  return tree
}

function order (a, b) {
  if (a.clusterId < b.clusterId) {
    return -1
  } else if (a.clusterId > b.clusterId) {
    return 1
  } else if (a.clusterId === b.clusterId) {
    return 0
  }
}
